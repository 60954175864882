@import './json-preview.css';
@import './tool-tip.css';
@import './loader.css';

@font-face {
  font-family: 'Bangers';
  src: local('Bangers'), url('/public/fonts/Bangers-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'),
    url('/public/fonts/Inconsolata-VariableFont_wdth,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('/public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  margin: 0;
  background-color: #faf5ff;
  color: #0e0e0f;
  width: auto !important;
  overflow-x: hidden !important;
}

body footer {
  background-color: #eb5646;
}

body .header p,
body .header ul,
body .header li {
  font-family: 'Bangers';
  letter-spacing: 1px;
}

/* Body text styles */
p,
ul,
li {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: 'Bangers', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}

.wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

body h1 {
  font-size: 44px;
  line-height: 56px;
}

body h2 {
  font-size: 28px;
  line-height: 34px;
}

body h3,
h4 {
  font-size: 18px;
  line-height: 24px;
}

body a {
  text-decoration: none;
  color: #0e0e0f;
}

body p {
  color: #0e0e0f;
}

body .modal-content {
  background-color: #faf5ff;
  color: #eb5646;
}

.max-width {
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
}

.accordian-container {
  margin-top: 20px !important;
}

.accordian-header {
  margin-bottom: 0px;
  font-family: 'Montserrat';
}

.about-us-page-hr {
  max-width: 100%;
  width: 1200px;
}

.about-us-btn {
  background-color: #0d6efd;
  font-size: 20px;
  border: 0;
  border-radius: 2px;
  padding: 26px 21px;
  text-align: center;
  color: white;
  letter-spacing: 3px;
  line-height: 18px;
  font-weight: bold;
}

@media (max-width: 530px) {
  .about-us-btn {
    font-size: 15px;
    padding: 10px 10px;
    letter-spacing: 2px;
  }
}

.about-us-para > p > a {
  color: #0d6efd !important;
}

.error-anchor {
  color: #0d6efd;
}

div ul:nth-last-child(2) > li:last-child  {
  font-size: 16px;
  margin-top: 10px;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: none !important;
}

.accordion-button:not(.collapsed) {
  border-color: none;
  color: inherit;
}

.accordion-button {
  border-bottom: 1px !important;
}

.scroll-to-top {
  font-family: 'Bangers';
  margin-bottom: 10px;
  color: white;
  text-align: right;
  right: 5px;
  bottom: 280px;
  position: fixed;
}

.scroll-to-top-btn:hover {
  background: #eb5646;
}

.about-us-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 765px) {
  .about-us-page > h1 {
    letter-spacing: 1px;
    line-height: 1px;
    margin: 25px;
    font-size: 25px;
  }
}

@media (max-width: 355px) {
  .about-us-page > h1 {
    letter-spacing: 1px;
    line-height: 1px;
    margin: 25px;
    font-size: 20px;
  }
}

.scroll-to-top-btn {
  background: black;
  border: 2px solid black;
  height: auto;
  min-height: 40px;
  min-width: 100px;
  width: auto;
}

/* Media query for screen sizes between 1150px and 1250px */
@media (min-width: 1130px) and (max-width: 1250px) {
  .nav-li {
    padding: 20px 10px;
  }
}

@media (max-width: 750px) {
  .nav-li {
    padding: 5px 5px;
    width: 100%;
    text-align: left;
  }
}

@media (max-width: 1500px) {
  .about-us-page {
    width: 100% !important;
  }
}

.about-us-para {
  text-align: left;
  width: 1200px;
  max-width: 100%;
}

@media (max-width: 1247px) {
  .about-us-para {
    max-width: 90%;
  }
}

figure {
  float: none;
  /* display: inline-block !important; */
  /* margin: 0; */
  margin-left: 40px !important;
  margin-right: 40px !important;

  margin-bottom: 5px !important;
  /* margin-right: 15px; */
  width: 165px;
  /* max-width: 165px; */
  /* margin-left: 40px; */
  /* padding-left: 0px; */
  margin-top: 0 !important;
}

figure > img {
  width: 100%;
}

.iiz {
  width: 1200px !important;
}

@media (max-width: 508px) {
  figure {
    width: 100px !important;
  }
}

@media (max-width: 365px) {
  figure {
    width: 80px !important;
  }
}

.hero-page {
  position: relative;
  height: 45vh;
  text-align: center;
}

.hero-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* border: 3px solid green; */
}

.note-div {
  background: #f2efff;
  text-align: center;
  margin: 2px;
}

.note-div p {
  margin: 0 auto;
  text-align: center;
  padding: 5px 0;
  color: #0e0e0f;
  display: inline;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: auto;
}

nav ul li {
  display: inline-block;
  padding: 23px 20px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

nav ul li a {
  font-weight: 600;
}

.top-section h2,
.top-section p {
  margin: 6px 0;
  padding: 0;
}

header {
  background: #000000;
  letter-spacing: 1px;
  position: sticky;
  top: 0;
  z-index: 9;
}

header a {
  color: #ffffff;
}

header .max-width {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-photo-album--row {
  margin: 5px !important;
}

.react-photo-album {
  padding: 5px;
}

header .wrapper-logo {
  flex: 0 0 35%;
  margin: 20px 0;
}

@media only screen and (max-width: 736px) {
  body {
    margin: 0;
    padding: 0;
    font-size: 15px;
  }
  .Logos {
    margin-top: 20px;
  }
}

h1.Logos {
  text-shadow: -3px -2px 0px #eb5646, -2px -11px 0 #dfc1c100, 2px -2px 0 #ffffff00;
  letter-spacing: 1px;
  color: #ffffff;
}

.footer-text .Logos {
  text-shadow: -3px -2px 0 #0e0e0e, -2px -11px 0 #dfc1c100, 2px -2px 0 #ffffff00;
}

header .wrapper-logo img {
  width: 130px;
}

header nav {
  flex: 1 1 100%;
  text-align: right;
}

.header-div {
  height: 70px;
}

.devtools {
  float: right;
  position: relative;
  bottom: 30px;
  right: 1%;
  cursor: pointer;
}

.devtools img {
  width: 28px;
}

.header-ul {
  overflow: hidden;
}

header nav li a:hover,
header nav li a.active {
  color: #eb5646;
}

.header-ul .hamburger-icon {
  display: none;
}

footer {
  /* margin-top: 75px; */
  background: #faf5ff;
  text-align: center;
}

footer a {
  color: #222222;
}

.footer-div-main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
}
.footer-div {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer-text {
  margin: 20px;
  font-size: 18px;
}

.powered-by {
  width: 190px;
  height: 70px;
  /* backgroundImage: `url(${social.icon.url})`, */
  color: #ffffff;
  text-shadow: -1px 3px black;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.powered-by img {
  /* max-height: 180px !important;
  max-width: 120px !important;
  margin: 0 10px; */
  max-width: 100%;
  height: auto;
  vertical-align: baseline;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos img {
  height: 50px;
  margin: 0 10px;
}

.footer-nav-li {
  padding: 0;
}

.col-half {
  margin: auto;
}

.col-quarter {
  display: inline-block;
  margin: auto 0;
}

.copyright {
  padding: 5px;
  text-align: center;
  text-shadow: -1px 3px black;
}
.copyright p {
  color: white;
}

.contact-form {
  width: 100%;
  background: #f7f7f7;
  height: 400px;
  border: 1px solid #0e0e0f;
}

.hero-form-title {
  font-family: 'Bangers';
  text-align: center;
  color: #eb5545 !important;
  padding: 10px;
}

.blog-roll,
.blog-entry {
  font-size: 0.9em;
}

.blog-post h2 {
  margin: 0 0 12px;
}

.blog-post .blog-meta {
  margin-top: 0;
  font-weight: 300;
  font-size: 24px;
}

.blog-post .blog-meta .author {
  padding-left: 1em;
}

/* button area in contact form */

.btn {
  border: none;
  font-size: 14px;
  line-height: 18px;
  border-radius: 2px;
  letter-spacing: 0.3px;
  padding: 12px 28px;
}

.regenerate-btn {
  background: #eb5646;
  color: #fff;
  margin-bottom: 2px;
}

.accept-btn {
  background: #7d4dff;
  color: #fff;
}

.tooltiptext {
  visibility: hidden;
  opacity: 0.7;
  width: auto;
  max-width: 150px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  margin: 20px;
  position: absolute;
  z-index: 1;
}

.regenerate-btn:hover .tooltiptext {
  visibility: visible;
}

.accept-btn:hover .tooltiptext {
  visibility: visible;
}

.primary-btn {
  background: #7d4dff;
  color: #fff;
  cursor: pointer;
}

.primary-btn:hover {
  background: #212529;
  color: #fff;
  /* font-weight: 600; */
}

.secondary-btn {
  border: 1px solid #7d4dff;
  color: #7d4dff;
}

.secondary-btn:hover {
  color: #7d4dff;
  font-weight: bold;
}

.tertiary-btn {
  background: #ffffff;
  color: #7d4dff;
  font-weight: bold;
}

.tertiary-btn:hover {
  color: #5c49be;
}

.hero-back-btn {
  position: fixed;
  text-align: center;
  color: #eb5646;
  top: 50%;
  opacity: 0.7;
}

.hero-back-btn:hover {
  color: #eb5646 !important;
}

.btn-close {
  width: 0.6rem;
  height: 0.6rem;
  padding: 0 0.5rem !important;
}

.modal-btn {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 135%;
  color: #647696;
}

.modal-btn:hover {
  color: #222222;
}

.text-field textarea {
  width: 550px;
  height: 120px;
  background-color: #f3f3f3;
  padding: 10px 15px;
  border: none;
}

/* Blog list section */
.hero-container,
.blog-container {
  margin: 53px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-list {
  display: flex;
  border: 2px solid #dacccc33;
  border-radius: 3px;
  margin-bottom: 12px;
}

.blog-list > a {
  text-align: center;
  width: 40%;
  background: #f2f2f2;
  display: inline-flex;
}

.blog-list-img {
  max-width: 100%;
}

.hero-column-left,
.hero-column-right,
.blog-column-left,
.blog-column-right {
  display: flex;
  flex-direction: column;
  padding: 60px;
}

.blog-column-left {
  width: 690px;
}

.blog-column-right {
  width: 360px;
}

.blog-column-right a:hover {
  color: #222222;
}

.blog-content h3 {
  color: #222222;
}

.blog-content {
  width: 60%;
  padding: 15px;
}

.blog-column-left .blog-content a {
  color: #7d4dff;
}

/* blog individual page */

.blog-detail {
  width: 690px;
}

/* home page */

.hero-description {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-bottom: 40px;
  color: #ffffff;
}

.blogpost-readmore {
  color: #7d4dff;
}

.card-cta {
  display: flex;
  margin-top: auto;
}

.blog-roll .blog-entry {
  font-size: 0.9em;
}

/* New Custom */

.hero-banner {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  background: #7d4dff;
  padding: 63px;
  color: #fff;
  flex-wrap: wrap;
}

.hero-content,
.home-content {
  text-align: left;
  margin-top: 65px;
  width: 475px;
}

.home-content p {
  margin-bottom: 32px;
}

.home-advisor-section {
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  margin: 63px;
  flex-wrap: wrap;
}

.member-section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 27px;
  text-align: center;
  margin-top: 43px;
}

.member-main-section {
  background: #f7f7f7;
  margin: auto;
  padding: 34px;
}

.member-head {
  text-align: center;
}

.content-section {
  width: 350px;
}

.content-section a {
  font-weight: bold;
  color: #7d4dff;
}

.home-featured-blogs {
  display: flex;
  padding: 20px;
  justify-content: center;
  text-align: left;
  flex-wrap: wrap;
}

.community-section {
  text-align: center;
}

.community-head {
  padding: 19px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.community-head a {
  display: inline-block;
  margin: auto 0 auto 0;
}

.featured-blog {
  width: 526px;
  border: #f7f5f5 solid;
  margin: 4px 33px;
}

.featured-blog img {
  object-fit: cover;
  inline-size: max-content;
}

.featured-content > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.featured-content {
  padding: 16px;
}

.demo-section {
  display: flex;
  justify-content: center;
  background: #f7f7f7;
  padding: 60px 0px;
  flex-wrap: wrap;
}

.cards {
  width: 410px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 15px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
}

/* home page ends here */

/* About us page start */

.about-content {
  color: #222222;
  text-align: left;
  margin-top: 35px;
  width: 475px;
}

.about-desc {
  color: #737b7d;
}

.mission-content-section {
  display: flex;
  width: 380px;
}

.mission-icon,
.mission-section-content {
  display: inline-block;
}

.mission-icon {
  height: 50px;
  margin-top: 12px;
  display: inline-block;
  padding-right: 32px;
}

.mission-section-content {
  display: inline-block;
}

.mission-content-top {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.mission-content-bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.story-advisor-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 35px;
  padding: 63px;
}

.about-team-section {
  text-align: center;
}

.hero-section {
  /* background: #; */
  text-align: center;
}

.hero-head-section {
  padding-top: 10px;
  width: auto;
  /* max-width: 920px; */
  transform: scale(0.7);
  margin: auto;
}

[data-testid='container'] {
  /* styles for elements with data-testid="container" */
  /* height: 640px !important; */
  width: 100%;
  cursor: pointer;
}

.story-content {
  text-align: left;
  margin-top: 35px;
  width: 475px;
}

.story-content p {
  margin-bottom: 32px;
}

.team-head-section {
  padding-top: 64px;
  width: auto;
  max-width: 920px;
  margin: auto;
}

.team-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding-top: 35px;
  margin: 5px;
}

.team-details {
  padding: 0px 15px;
}

.hero-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.hero-details {
  padding: 0px 15px;
  position: relative;
  /* width: 50%; */
}

/* contact page */

.contact-page-content {
  width: 50%;
  /* padding-top: 20px; */
  padding-right: 80px;
}
.contact-page-content img {
  max-width: 100%;
}

.contact-page-form {
  width: 50%;
  padding: 3px;
  padding: 15px;
  /* border: 1px solid #ccc; */
  border-radius: 3px;
  margin-bottom: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.contact-page-content > h2 {
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
}

.contact-page-content > p {
  font-size: 16px;
  color: #737b7d;
}

.with-border {
  padding: 3px;
  padding: 15px;
  /* border: 1px solid #ccc; */
  border-radius: 3px;
  margin-bottom: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

span.title {
  margin-top: 20px;
  margin-left: 12px;
  font-weight: bold;
  font-size: 24px;
  color: #221461;
  /*The change*/
  display: inline-block; /*or display:block;*/
}

.input-fields,
.text-field,
.checkbox-fields,
.select-field {
  margin-bottom: 30px;
}

.input-label {
  text-transform: capitalize;
}

.input-fields input {
  width: 100%;
  background-color: white;
  padding: 16px 15px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transition: 0.3s box-shadow;
}

.input-fields input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #7d4dff;
  padding: 8px 21px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  color: white;
  cursor: pointer;
  /* transition: background 0.2s ease-in-out; */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transition: 0.3s box-shadow;
}

.input-fields input[type='file']::file-selector-button:hover {
  background: #212529;
}

.text-field input {
  width: 100%;
  height: 120px;
  background-color: #f3f3f3;
  padding: 25px 15px;
  border: none;
}

.text-field textarea {
  width: 100%;
  height: 120px;
  background-color: white;
  padding: 25px 15px;
  border: none;
  /* border-radius: 3px; */
  /* border: 1px solid #dcdcdc; */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transition: 0.3s box-shadow;
}

.select-field select:focus,
.text-field textarea:focus,
.input-fields input:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 5px #719ece;
}
.select-field select {
  width: 100%;
  height: 60px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transition: 0.3s box-shadow;

  background-color: white;
  color: #0e0e0f;
  padding: 0px 15px;
  /* border: none; */
  -webkit-appearance: none !important;
  -moz-appearance: none !important;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
}

/* checkbox */
.checkbox-field input[type='checkbox'] {
  display: none;
  visibility: hidden;
}

.checkbox-field .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checkbox-field .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-field .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
}
.checkbox-field .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.checkbox-field .cbx span:first-child:before {
  content: '';
  width: 100%;
  height: 100%;
  background: #506eec;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-field .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-field .cbx:hover span:first-child {
  border-color: #506eec;
}

.checkbox-field .inp-cbx:checked + .cbx span:first-child {
  background: #7d4dff;
  border-color: #5a20b9;
  animation: wave-46 0.4s ease;
}
.checkbox-field .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-field .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}
/* checkbox */

.consent {
  font-size: 10px;
  background-color: white;
  padding: 10px;
  overflow-y: auto;
  height: 100px;
  box-shadow: 0 0 4px rgb(0 0 0 / 30%);
  transition: 0.3s box-shadow;
}

.consent p {
  font-family: 'Inconsolata';
}

.consent a {
  color: blue;
}

.contact-maps-content {
  margin-top: 70px;
  width: 50%;
}

/* blog list */

.blog-page-banner {
  background: #715cdd;
  text-align: center;
}

.blog-page-content {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 482px;
  padding: 58px 0px 58px 0px;
}

.error-page {
  position: relative;
  height: 65vh;
  text-align: center;
}

.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header .menu-btn {
  display: none;
}

.contact-page-section,
.contact-maps-section {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 45px 0;
}

.maps-details {
  width: 50%;
}

.maps-details img {
  max-width: 100%;
}

.modal {
  overflow: hidden;
  border-radius: 10px;
}

.modal-dialog {
  max-width: 680px;
}

.devtools-modal-title {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.00019em;
  margin-top: 2px;
}

.modal-body {
  min-height: 8.625rem;
  overflow: auto;
}

.modal-header {
  height: 0.51rem 1rem;
}

.modal-footer {
  padding: 0.61rem 0.75rem;
}

.social-container {
  height: 50px;
}
.share-icon {
  height: 30px;
  width: 30px;
}

.share-icon:hover {
  height: 40px;
  width: 40px;
}

@media screen and (min-width: 767px) and (max-width: 1130px) {
  body {
    font-size: 15px;
  }

  nav ul li {
    padding: 15px 10px;
  }
  .Logos {
    font-size: 29px;
  }
}

/* ========================== */

/*          Mobile            */

/* ========================== */
@media screen and (min-width: 320px) and (max-width: 767px) {
  body {
    font-size: 15px;
  }

  header a {
    color: #ffffff;
  }

  .header-div {
    background-color: #000000;
    color: #dddde3;
    height: auto;
  }

  .footer-div {
    flex-direction: column;
  }

  .col-half {
    padding: 0px;
    margin: 0px;
  }

  .col-quarter {
    padding: 15px 0;
  }

  .footer-nav-li {
    padding: 7px;
  }

  .nav-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;
  }

  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    /* background-color: #faf5ff; */
  }

  .header li a {
    display: block;
    padding: 0 20px;
    text-decoration: none;
  }

  /* .header li a:hover,
  .header .menu-btn:hover {
    background-color: #f4f4f4;
  } */

  /* menu */
  .header .menu {
    clear: both;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .header .header-div {
    display: inline-block;
    width: 100%;
  }

  .header .wrapper-logo {
    float: left;
    margin-left: 15px;
    margin-bottom: auto;
    margin-top: 13px;
  }

  .menu-icon {
    float: right;
    margin-top: 2px;
  }

  /* menu icon */
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }

  .header .menu-icon .navicon {
    background: #ffffff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #ffffff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .header .menu-icon .navicon:before {
    top: 5px;
  }

  .header .menu-icon .navicon:after {
    top: -5px;
  }

  .header .menu-btn:checked ~ nav {
    max-height: 240px;
    overflow: auto;
  }

  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }

  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  nav ul li {
    padding: 8px 0;
  }

  h1 h2 h3 h4 {
    font-weight: bold;
    letter-spacing: 0.1px;
  }

  body h1 {
    /* font-size: 29px;
    line-height: 38px; */
    font-size: 29px;
    line-height: 0px;
    letter-spacing: 0.2px;
  }

  body h2 {
    font-size: 20px;
    line-height: 28px;
  }

  body h3 {
    font-size: 14px;
    line-height: 24px;
  }

  .btn {
    border: none;
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2px;
    letter-spacing: 0.3px;
    padding: 8px 21px;
  }

  .secondary-btn {
    border: 1px solid #7d4dff;
    color: #7d4dff;
  }

  .hero-banner {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    background: #7d4dff;
    padding: 28px;
    color: #fff;
    flex-wrap: wrap;
  }

  .home-advisor-section {
    background: #fff;
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    padding: 28px;
    margin: 0;
    flex-wrap: wrap-reverse;
  }

  .note-div p {
    margin: 0 auto;
    text-align: center;
    padding: 5px 0;
    color: #0e0e0f;
    display: block;
  }

  .blog-page-content {
    color: #fff;
    text-align: left;
    width: 90%;
  }

  .blog-list > img {
    max-width: 100%;
  }

  .blog-list-img {
    margin: auto;
  }

  .blog-list > a {
    width: 100%;
  }

  .blog-column-left {
    padding: 0;
  }

  .blog-column-right {
    background: #f2f2f2;
    padding: 17px;
    height: fit-content;
  }

  .blog-content {
    width: auto;
  }

  .hero-banner > img {
    max-width: 100%;
    margin-top: 40px;
  }

  .home-content {
    text-align: left;
    margin-top: 9px;
    width: 475px;
  }

  .home-advisor-section > img {
    max-width: 100%;
    margin-top: 40px;
  }

  .featured-blog {
    width: 90%;
    margin: 5px auto;
  }

  .community-head {
    padding: 19px;
    display: block;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .community-head a {
    display: block;
    margin: auto 0 auto 0;
  }

  .home-featured-blogs {
    display: flex;
    padding: 0 5px 27px;
    justify-content: center;
    text-align: left;
    flex-wrap: wrap;
  }

  .content-section {
    margin: 12px;
  }

  .featured-blog > img {
    width: 100%;
  }

  .member-section {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 27px;
    text-align: center;
    margin-top: 14px;
  }

  .cards {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 22px;
    display: flex;
    margin: 16px 17px 15px 17px;
    max-width: 100%;
  }

  .mission-icon {
    margin-top: 12px;
    display: inline-block;
    padding: 0;
  }

  .mission-content-top {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    flex-wrap: wrap;
  }

  .mission-content-bottom {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    flex-wrap: wrap;
  }

  .mission-content-section {
    width: 313px;
    display: inline-block;
    text-align: center;
  }

  .story-advisor-section > img {
    max-width: 100%;
  }

  .team-head-section {
    padding-top: 64px;
    margin: auto;
    width: 90%;
  }

  .blog-list {
    display: block;
    border: 2px solid #dacccc33;
    border-radius: 3px;
    margin-bottom: 12px;
  }

  .contact-page-content {
    width: auto;
    padding: 20px 0;
    text-align: left;
  }

  .contact-page-content img {
    max-width: 100%;
  }

  .blog-container {
    padding: 26px;
  }

  .contact-page-form {
    width: 100%;
  }

  .contact-page-section,
  .contact-maps-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0;
  }

  .maps-details {
    width: auto;
    overflow: hidden;
  }

  .contact-maps-content {
    width: auto;
  }

  .json-preview {
    position: absolute;
    top: 2.6rem;
    right: 2.5rem;
  }
}

/* ========================== */

/*          Tablet            */

/* ========================== */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  body p {
    color: #0e0e0f;
  }

  .btn {
    border: none;
    font-size: 14px;
    line-height: 18px;
    border-radius: 2px;
    letter-spacing: 0.3px;
    padding: 11px 17px;
  }

  .secondary-btn {
    border: 1px solid #7d4dff;
    color: #7d4dff;
  }

  body h1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.1px;
  }

  body h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  body h3,
  body h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  header nav {
    display: block;
  }

  .hero-banner {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    background: #7d4dff;
    padding: 63px;
    color: #fff;
    flex-wrap: nowrap;
  }

  .hero-description {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.3px;
    margin-bottom: 40px;
  }

  .home-content {
    width: 50%;
    margin-top: 12px;
  }

  .hero-banner > img {
    width: 50%;
    margin: 27px 0px 0px 0px;
  }

  .home-advisor-section {
    background: #fff;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin: 80px 63px 63px 63px;
    flex-wrap: nowrap;
  }

  .home-advisor-section > img {
    width: 50%;
  }

  .member-section {
    display: flex;
    justify-content: space-evenly;
    padding: 27px;
    text-align: center;
    margin-top: 43px;
    flex-wrap: nowrap;
  }

  .home-featured-blogs {
    display: flex;
    padding: 20px;
    justify-content: center;
    text-align: left;
    flex-wrap: nowrap;
  }

  .featured-blog {
    width: 335px;
    border: #f7f5f5 solid;
    margin: 4px 14px;
  }

  .featured-blog > img {
    max-width: 100%;
    height: 260px;
    object-fit: cover;
    inline-size: max-content;
  }

  .featured-content > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .demo-section {
    display: flex;
    justify-content: center;
    background: #f7f7f7;
    padding: 60px 0px;
    flex-wrap: nowrap;
  }

  .cards {
    width: 334px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 15px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }

  .home-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .mission-content-section {
    display: block;
    text-align: center;
    width: fit-content;
    padding: 20px;
  }

  .mission-icon {
    padding: 0;
  }

  .team-details > p {
    margin-top: -10px;
    margin-bottom: 50px;
  }

  .team-head-section {
    margin: auto;
    width: 79%;
  }

  .contact-maps-section {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 45px 0;
  }

  .contact-page-section {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 45px 0;
  }

  .maps-details > img {
    max-width: 100%;
  }

  .maps-details {
    width: 50%;
  }

  .contact-maps-content {
    width: 50%;
    padding: 0px 47px 1px 52px;
    margin-top: 0;
  }

  .contact-page-section p {
    font-size: 14px;
    line-height: 20px;
  }

  .blog-column-left {
    width: 65%;
    padding: 30px;
  }

  .blog-column-right {
    width: 35%;
    padding: 18px;
    margin: 33px;
    background: #f2f2f2;
    height: fit-content;
  }

  .blog-list-img {
    max-width: 100%;
  }

  .blog-list > a {
    text-align: center;
    background: #f2f2f2;
    display: block;
    width: 100%;
  }

  .blog-content {
    width: 100%;
  }

  .blog-list {
    display: block;
    border: 2px solid #dacccc33;
    border-radius: 3px;
    margin-bottom: 12px;
  }

  .blog-container {
    margin: 0 auto;
    margin-top: 53px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .blog-detail p {
    font-size: 14px;
    line-height: 20px;
  }

  .blog-detail {
    width: 65%;
    margin-left: 23px;
  }
}

/* scroll bar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e0e2e8;
}

::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

/* scroll end */

/* json preview css*/

.object-key {
  color: #c8501e;
}

.string-value {
  color: #5d9ccb;
}

.hero-gallery-container {
  border: 1px solid #eee;
  border-radius: 4px;
  box-sizing: content-box;
  align-items: center;
  margin-bottom: 10;
  padding-top: 8px;
}

.hero-gallery-container:hover {
  box-shadow: 0 0.5rem 1rem #eb5646 !important;
}

.hero-gallery-title {
  padding-top: 8px;
  padding-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-weight: 500;
  background: #000000;
  color: white;
}
